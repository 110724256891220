import EthnicBackgroundTemplate from '@/modules/questionnaire/components/steps/common/identity/ethnic-background/EthnicBackgroundTemplate.vue';

import { ETHNICITY, OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Identity/EthnicBackground/EthnicBackgroundTemplate',
  component: EthnicBackgroundTemplate
};

const createStory = props => () => ({
  components: { EthnicBackgroundTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><ethnic-background-template v-bind="$options.storyProps" /></div>'
});

export const ethnicityEmpty = createStory({
  ethnicity: [],
  otherEthnicity: ''
});

export const ethnicityOther = createStory({
  ethnicity: [OTHER_VALUE_BINDINGS.value],
  otherEthnicity: 'other ethnicity'
});

export const ethnicityMiddleEastern = createStory({
  ethnicity: [ETHNICITY.MIDDLE_EATERN],
  otherEthnicity: ''
});
